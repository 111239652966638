import ky from "ky";
import { IUserData, IUserNetworkData } from "./types";


const API_URL = "https://dev.investor.api.vegait.com/api/1/";

class UserApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async listUserData(): Promise<IUserData | undefined> {
    const headers = await this.getHeaders("application/json");
    const userID = localStorage.getItem("userId");
    try {
      const response = await ky.get(
        `${API_URL}user/${userID}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar dados do usuário", error);
    }
  }

  public async listUserNetworks(
    userId: number
  ): Promise<IUserNetworkData[] | undefined> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}user/user-networks/${userId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar dados do usuário", error);
    }
  }
}

export default new UserApi();
