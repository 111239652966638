import ky from "ky";
const API_URL = "https://dev.investor.api.vegait.com/api/1/";

class PointsAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async getUnity(): Promise<any[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}room/file/${networkUnitId}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar dados da unidade:", error);
      throw error;
    }
  }

  public async getOwners(): Promise<any[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}user/investor/network-unit/${networkUnitId}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar dados de proprietários:", error);
      throw error;
    }
  }


  public async postNewPoint(
    transactionPoints: number,
    pointType: number,
    roomId: number,
    userId: number,
    title: string,
    detailing: string,
  ): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const dataBody = {
      transactionPoints: transactionPoints,
      pointType: pointType,
      roomId: roomId,
      userId: userId,
      title: title,
      detailing: detailing,
    };

    try {
      const headers = await this.getHeaders();
      await ky.post(`${API_URL}points`, {
        json: dataBody,
        headers,
      });
      return;
    } catch (error) {
      console.error("Erro ao cadastrar nova pontuação", error);
      throw error;
    }
  }

  public async getPoints(): Promise<any[]> {
    const user = localStorage.getItem("userId");

    if (!user) {
      throw new Error("Network user não encontrado no localStorage.");
    }

    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}points/extract-points/${user}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();

      return data;
    } catch (error) {
      console.error("Erro ao buscar pontos:", error);
      throw error;
    }
  }

  public async getBigNumberPoints(): Promise<any[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network networkUnitId não encontrado no localStorage.");
    }

    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}points/extract-points/big-number/${networkUnitId}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();

      return data;
    } catch (error) {
      console.error("Erro ao buscar pontos:", error);
      throw error;
    }
  }

  public async upadtePoint(
    transactionPoints: number,
    pointType: number,
    roomId: number,
    userId: number,
    title: string,
    detailing: string,
  ): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const dataBody = {
      transactionPoints: transactionPoints,
      pointType: pointType,
      roomId: roomId,
      userId: userId,
      title: title,
      detailing: detailing,
    };
    try {
      const headers = await this.getHeaders();
      await ky.put(`${API_URL}points`, {
        json: dataBody,
        headers,
      });
      return;
    } catch (error) {
      console.error("Erro ao cadastrar nova pontuação", error);
      throw error;
    }
  }
}

export default new PointsAPI();
