import ky from "ky";
import { IListUsers } from "./types";

const API_URL = "https://dev.investor.api.vegait.com/api/1/";

class FileApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewFile(
    Icon: File | null,
    Files: File[] | File,
    Name: string,
    type: string,
    CategoryId: string,
  ): Promise<unknown> {
    const formData = new FormData();
    const selectedNetwork = localStorage.getItem("selectedNetwork");

    formData.append("Name", Name);
    formData.append("Type", type);
    formData.append("CategoryId", CategoryId);
    if (selectedNetwork) {
      formData.append("NetworkUnitId", selectedNetwork);
    } else {
      console.error("NetworkUnitId não encontrado");
    }  
    if (Icon) {
      formData.append("Icon", Icon);
    }
  
    if (Array.isArray(Files)) {
      Files.forEach((file) => {
        formData.append("File", file);
      });
    } else if (Files instanceof File) {
      formData.append("File", Files);
    }
  
    try {
      const headers = await this.getHeaders();
      const data = await ky.post(`${API_URL}file`, {
        body: formData,
        headers,
      });
      return data;
    } catch (error) {
      console.error("Erro ao cadastrar novo arquivo", error);
      throw error;
    }
  }
  

  public async listFile(pageSize: number, offSet: number, selectActive: number): Promise<unknown> {
    const headers = await this.getHeaders("application/json");

    try {
      const response = await ky.get(
        `${API_URL}file/${selectActive}/${pageSize}/${offSet}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar arquivos", error);
    }
  }

  public async listUsers(): Promise<IListUsers[] | undefined> {
    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork");

    try {
      const response = await ky.get(
        `${API_URL}user/network-unit/investor/${selectedNetwork}`,
        { headers }
      );

      return response.json();
    } catch (error) {
      console.error("Erro ao listar usuários", error);
    }
  }

  public async deleteFile(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `${API_URL}file/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar comunicado", error);
    }
  }

  public async editFile(
    id: string,
    Icon: File | null,
    Files: File[] | File,
    name: string,
    type: string,
    category: string,
  ): Promise<unknown> {
    const formData = new FormData();

    formData.append("Name", name);
    formData.append("Type", type);
    formData.append("CategoryId", category);


    if (Icon) {
      formData.append("Icon", Icon);
    }

    if (Array.isArray(Files)) {
      Files.forEach((file) => {
        formData.append("File", file);
      });
    } else if (Files instanceof File) {
      formData.append("File", Files);
    }

    try {
      const headers = await this.getHeaders();
      headers["Content-Type"] = "multipart/form-data";
      const data = await ky.put(`${API_URL}file/${id}`, {
        body: formData,
        headers,
      });
      return data;
    } catch (error) {
      console.error("Erro ao editar arquivo", error);
      throw error;
    }
  }
}

export default new FileApi();
