
import ky from "ky";

const API_URL = "https://dev.investor.api.vegait.com/api/1/";

class CategoryAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewCategory(title: string): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");
  
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
  
    const dataBody = {
      titulo: title,
      networkUnitId: networkUnitId,
    };
  
    try {
      const headers = await this.getHeaders();
      const response = await ky.post(`${API_URL}category`, {
        json: dataBody, 
        headers,
      });
      return response.json(); 
    } catch (error) {
      console.error("Erro ao cadastrar nova categoria", error);
      throw error;
    }
  }

  public async getListCategory(selectActive?: any): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");
  
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
  
    try {
      const headers = await this.getHeaders();
      const response = await ky.get(`${API_URL}category/${selectActive}`, {
        headers, 
      });
      return response.json(); 
    } catch (error) {
      console.error("Erro ao listar categorias", error);
      throw error;
    }
  }


  public async editCategory(title: string, active: boolean, categoryId: any): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");
  
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
  
    const dataBody = {
      Title: title,
      Active: active,
    };
  
    try {
      const headers = await this.getHeaders();
      const response = await ky.put(`${API_URL}category/${categoryId}`, {
        json: dataBody, 
        headers,
      });
      return response
    } catch (error) {
      console.error("Erro ao editar categoria", error);
      throw error;
    }
  }

  public async deleteCategory(id: any): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");
  
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
  
    try {
      const headers = await this.getHeaders();
      const response = await ky.delete(`${API_URL}category/${id}`, {
        headers, 
      });
      return response.json(); 
    } catch (error) {
      console.error("Erro ao deletar categorias", error);
      throw error;
    }
  }
}

export default new CategoryAPI();
